<template>
  <div class="allNews">
    <el-card>
      <div slot="header" class="clearfix">
        <span class="title">全部动态</span>
      </div>
      <div>
        <ul v-if="collegeNews.length !== 0">
          <li v-for="item in collegeNews" :key="item.id" class="new">
            <div class="content">
              <i class="el-icon-document-copy"></i>
              <span>{{ item.content }}</span>
            </div>
            <div>{{ item.createTime }}</div>
          </li>
        </ul>
        <div v-else>你暂时还没有动态哦！</div>
      </div>
    </el-card>
    <el-pagination
      v-if="pager.toatl !== 0"
      background
      :current-page="pager.current"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="pager.size"
      layout="total, prev, pager, next, sizes, jumper"
      :total="pager.total"
      class="pagination"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
  </div>
</template>

<script>
import { getCollegeNews } from '@/api/dashboard'
import to from 'await-to'
export default {
  data() {
    return {
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      collegeNews: [],
    }
  },
  created() {
    this.getCollegeNewsData()
  },
  methods: {
    async getCollegeNewsData() {
      const [res, err] = await to(getCollegeNews({ ...this.pager }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.collegeNews = res.data.list
      this.pager.total = res.data.total
    },
    handleSizeChange(val) {
      this.pager.size = val
      this.pager.current = 1
      this.getCollegeNewsData()
    },
    handleCurrentChange(val) {
      this.pager.current = val
      this.getCollegeNewsData()
    },
  },
}
</script>

<style scoped lang="scss">
.allNews {
  ::v-deep .el-icon-document-copy {
    font-size: 20px;
    color: #ff7b33;
  }
  .new {
    padding: 20px 10px;
    border-bottom: 1px dashed #979797;
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    .content {
      span {
        margin-left: 15px;
        font-size: 14px;
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .pagination {
    margin: 20px 0;
  }
}
</style>
